<template>
	<ion-page>
		<A-header title="Summary" />
		
		<ion-content :fullscreen="true">
			<!-- <A-collapse-header title="Summary" /> -->

			<ion-card v-for="list in lists" :key="`shopping-list-${list.id}`" @click="$router.push(`/list/${list.id}`)">
				<ion-card-header>
					<ion-card-title>{{ list.title }}</ion-card-title>
				</ion-card-header>

				<ion-card-content>
					<ion-list lines="none">
						<div v-for="item in list.items" :key="`item-${list.id}-${item.id}`">
							<ion-item v-if="!item.is_checked">
								<ion-label>{{ item.name }}</ion-label>
								<ion-icon :icon="ellipse" slot="start" :class="`priority-${item.priority}`"></ion-icon>
							</ion-item>
						</div>
					</ion-list>
				</ion-card-content>
			</ion-card>

			<A-footer-safearea />
		</ion-content>
	</ion-page>
</template>
<script>
import AHeader 			from '@/plugins/appshoppinglist/layout/a-header.vue'
import ACollapseHeader 	from '@/plugins/appshoppinglist/layout/a-collapse-header.vue'
import AFooterSafearea  from '@/plugins/appshoppinglist/layout/a-footer-safearea.vue'
import { add, ellipse } 			from 'ionicons/icons'

export default {
	components: {
		AHeader,
		// ACollapseHeader,
		AFooterSafearea
	},

	data() {
		return {
			add,
			ellipse,
			priorities: {
				0: 'None',
				1: 'Optional',
				2: 'Should',
				3: 'Normal',
				4: 'Important',
				5: 'High priority',
			}
		}
	},

	computed: {
		lists() {
			const sortedList = this.$store.getters['shoppingList/lists'].map(list => {
				list.priority = 0

				list.items.forEach(item => {
					let itemPriority = parseInt(item.priority)
					
					if (item.priority == 4) {
						list.priority += item.priority * 2
					} else if (item.priority == 5) {
						list.priority += item.priority * 3
					} else {
						list.priority += itemPriority
					}
				})

				return list
			}).sort((a, b) => a.priority > b.priority ? -1 : 1)
			
			return sortedList
		}
	},

	ionViewWillEnter() {
		this.$store.dispatch('shoppingList/loadLists')
	}
}
</script>

<style lang="sass" scoped>
ion-card
	--background: var(--ion-background-color, #fff)
	box-shadow: none !important

	ion-card-title
		font-size: 17px

ion-card-content, ion-card-header
	padding-top: 0 !important
	padding-left: 0 !important
	padding-bottom: 5px

ion-card-content
	padding-bottom: 0 !important

ion-list
	background: var(--ion-background-color, #fff)

ion-item
	--padding-start: 0
	height: 35px
	--background: var(--ion-background-color, #fff)

	ion-label
		font-size: 16px !important

	ion-icon
		font-size: 13px

</style>