<template>
	<ion-header :translucent="true">
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-back-button v-if="defaultHref" :default-href="defaultHref" :text="text" />
				<ion-menu-button v-else-if="isLoggedIn" color="primary"></ion-menu-button>
			</ion-buttons>

			<ion-title @click="$emit('onTitleClick')">{{ title }}</ion-title>

			<ion-buttons slot="end">
				<ion-button @click="$emit('onDelete')" v-if="enableDelete" :disabled="!isConnected">
					<ion-icon :icon="trashOutline" class="delete" />
				</ion-button>

				<ion-button @click="$emit('onNew')" v-if="enableNew" :disabled="!isConnected">
					<ion-icon :icon="addCircleOutline" />
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
</template>

<script>
import { trashOutline, addCircleOutline } 	from 'ionicons/icons'
import { mapGetters }						from 'vuex'
import { Network } 							from '@capacitor/network'

export default {
	props: {
		title: {
			type: String
		},

		text: {
			type: String,
			default: "Back"
		},

		defaultHref: {
			type: Object
		},

		enableDelete: {
			type: Boolean,
			default: false
		},

		enableNew: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		]),
	},

	data() {
		return {
			trashOutline,
			addCircleOutline,
			isConnected: true
		}
	},

	async created() {
		Network.addListener('networkStatusChange', status => {
			this.isConnected = status.connected
		})

		await Network.getStatus()
			.then(status => this.isConnected = status.connected)
	}
}
</script>

<style lang="sass">
ion-header:not(.header-collapse-condense-inactive)
	ion-back-button::part(text)
		transition-duration: 200ms
		opacity: 0

ion-icon.delete
	color: var(--ion-color-danger)
</style>

